import type { FC } from 'react'

import classNames from 'classnames'

import { Show } from 'common/components/Show/Show'
import { UtilService } from 'common/services/utilService'

import { useCartManager, type ICartTaxes } from 'features/Cart'
import { PaymentPriceItem } from 'features/Payment/components/PaymentPriceItem/PaymentPriceItem'
import { PAYMENT_CONSTANTS } from 'features/Payment/constants/paymentConstants'

import styles from './paymentPrice.module.scss'
import { BOOKING_TYPE_CODE } from 'features/Booking/constants/booking.constants'

interface IProps {
  limit: number
  isLeaseShop?: boolean
  amount: ICartTaxes
  className?: string
  totalLabel?: string
  nextAmount?: number
  nextAmountLabel?: string
}

export const PaymentPrice: FC<IProps> = ({
  limit,
  amount,
  isLeaseShop,
  className,
  totalLabel = PAYMENT_CONSTANTS.TOTAL,
  nextAmount,
  nextAmountLabel,
}) => {
  const { activeBooking } = useCartManager()
  const isEhrLease = activeBooking?.requestId === BOOKING_TYPE_CODE.EHR

  return (
    <div className={classNames(styles.parent, className)}>
      <hr />
      <PaymentPriceItem title={PAYMENT_CONSTANTS.SUBTOTAL} price={amount?.subtotal || 0} />

      <PaymentPriceItem title={PAYMENT_CONSTANTS.TAXES_SALES} price={amount?.taxes || 0} />
      {amount?.fees > 0 && (
        <PaymentPriceItem
          title={isEhrLease ? PAYMENT_CONSTANTS.FEES_EHR : PAYMENT_CONSTANTS.FEES_INSURANCE}
          price={amount?.fees}
        />
      )}
      {amount?.room_fees > 0 && (
        <PaymentPriceItem title={PAYMENT_CONSTANTS.FEES_AFTER_HOURS} price={amount?.room_fees} />
      )}
      {amount?.ehr_setup_fee > 0 && (
        <PaymentPriceItem title={PAYMENT_CONSTANTS.FEES_EHR} price={amount?.ehr_setup_fee} />
      )}

      <Show when={!isLeaseShop}>
        <PaymentPriceItem price={amount?.deposit} title={PAYMENT_CONSTANTS.DEPOSIT} />
      </Show>

      <hr />

      <PaymentPriceItem title={totalLabel} price={amount?.total || 0} />

      {nextAmount && (
        <>
          <hr />
          <PaymentPriceItem title={nextAmountLabel} price={nextAmount} />
        </>
      )}

      <Show when={limit > 0 && isFinite(limit)}>
        <p className='cart-inform'>
          {PAYMENT_CONSTANTS.CURRENTLY}
          {UtilService.numberToDollar(limit)}
        </p>
      </Show>
    </div>
  )
}

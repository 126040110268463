import { type FC, memo, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { TabPanel } from 'common/components/TabPanel/TabPanel'

import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'
import { Others } from './Others/Others'
import { Rooms } from './Rooms/Rooms'
import type { PromotionDetailsProps } from './promotionDetails.types'
import { PromotionContainer } from './PromotionContainer/PromotionContainer'

export const PromotionDetails: FC<PromotionDetailsProps> = memo(
  ({ promotion, isLoading, isSuccess }) => {
    const location = useLocation()

    const tabs = useMemo(() => {
      return [
        {
          path: PATH_SEGMENT.ROOMS,
          title: 'Rooms',
        },

        {
          path: PATH_SEGMENT.OTHERS,
          title: 'Others',
        },
      ]
    }, [])

    const routesConfig = useMemo(
      () => ({
        [PATH_SEGMENT.ROOMS]: (
          <Rooms promotion={promotion} isLoading={isLoading} isSuccess={isSuccess} />
        ),
        [PATH_SEGMENT.OTHERS]: <Others />,
        [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.ROOMS} replace />,
      }),
      [promotion],
    )

    const showMore = useMemo(() => {
      return location.pathname.includes(PATH_SEGMENT.ROOMS)
    }, [location.pathname])

    return (
      <PromotionContainer
        displayCol
        isAdditionInfoFull
        promotion={promotion}
        isLoading={isLoading}
        showMedia={showMore}
        showAdditionInfo={showMore}>
        <TabPanel tabs={tabs} />
        {generateRouter(routesConfig)}
      </PromotionContainer>
    )
  },
)

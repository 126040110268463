import { Checkbox } from 'antd'
import styles from './bookingSlots.module.scss'
import { Icon } from '@iconify/react'
import classNames from 'classnames'
import { useToggle } from 'react-use'
import { BookingSlot } from 'features/Booking/components/BookingSlot/BookingSlot'
import { useMemo } from 'react'
import dayjs from 'dayjs'

export type BaseSlot = {
  id: number
  is_active: number
  title: string
  price?: number
  start_time?: string
  end_time?: string
  fee?: number
}

type Props<T extends BaseSlot> = {
  groupName: string
  slots: T[]
  selectedSlots: number[]
  isGroupDisabled?: boolean
  hasExpand?: boolean
  getIsSlotForceDisabled?: (slotId: number) => boolean
  onSlotToggle: (slotId: number) => void
  onGroupToggle: (slotIds: number[]) => void
  hasIntervalTitle?: boolean
}

export const BookingSlotGroup = <T extends BaseSlot>({
  groupName,
  slots,
  selectedSlots,
  isGroupDisabled: isGroupDisabledProp,
  hasExpand = true,
  getIsSlotForceDisabled = () => false,
  onSlotToggle,
  onGroupToggle,
  hasIntervalTitle: hasTitleInterval = false,
}: Props<T>) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false)

  const areAllSlotsDisabled = slots.every((slot) => !slot.is_active)

  const isGroupDisabled = areAllSlotsDisabled || isGroupDisabledProp
  const currentGroupSlotsIds = slots.map((slot) => slot.id)
  const selectedGroupSlots = slots.filter((slot) => selectedSlots.includes(slot.id))
  const isAllSelected = currentGroupSlotsIds.length === selectedGroupSlots.length
  const isPartiallySelected =
    slots.some((slot) => selectedSlots.includes(slot.id)) && !isAllSelected

  const handleToggleExpand = (value?: boolean) => {
    if (!hasExpand) return
    toggleIsExpanded(value)
  }

  const handleGroupCheckboxChange = () => {
    const additionalSlotsToRemove = []
    slots.forEach((slot) => {
      if (selectedSlots.includes(slot.id) && !slot.is_active) {
        additionalSlotsToRemove.push(slot.id)
      }
    })

    const filteredSlotsIds = slots.filter((slot) => slot.is_active).map((slot) => slot.id)

    onGroupToggle([...filteredSlotsIds, ...additionalSlotsToRemove])
  }

  const title = useMemo(() => {
    if (!hasTitleInterval) return groupName
    try {
      const formatTime = (time: string) => dayjs(time, 'HH:mm:ss').format('h:mm A')

      const hasStar = slots.some((slot) => slot?.fee > 0)
      const append = hasStar ? ' *' : ''

      return `${groupName}${append} (${formatTime(slots[0].start_time)} - ${formatTime(
        slots.at(-1).end_time,
      )})`
    } catch (error) {
      return groupName
    }
  }, [groupName, slots, hasTitleInterval])

  return (
    <>
      <div
        key={groupName}
        className={styles.groupContainer}
        data-expanded={isExpanded}
        data-has-expand={hasExpand}
        onClick={() => handleToggleExpand()}
        data-is-disabled={areAllSlotsDisabled}>
        <Checkbox
          disabled={isGroupDisabled}
          className={styles.selectAllCheckbox}
          indeterminate={isPartiallySelected}
          checked={isAllSelected}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onChange={handleGroupCheckboxChange}
        />

        <span className={styles.groupTitle}>{title}</span>
        <div onClick={(e) => e.stopPropagation()}>
          <button className={styles.iconButton} onClick={() => handleToggleExpand()}>
            <Icon
              icon='ion:chevron-down'
              className={classNames(styles.arrowIcon, isExpanded && styles.arrowIconExpanded, {
                hidden: !hasExpand,
              })}
              width={24}
              height={24}
            />
          </button>
        </div>
      </div>

      {isExpanded && (
        <div className={styles.groupContent}>
          {slots.map((slot) => {
            return (
              <BookingSlot
                isDisabled={getIsSlotForceDisabled(slot.id)}
                key={slot.id}
                slot={slot}
                isSelected={selectedSlots.includes(slot.id)}
                onToggle={() => onSlotToggle(slot.id)}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

import { useRef } from 'react'
import { useClickAway, useToggle } from 'react-use'

import classNames from 'classnames'

import { useAuth } from 'app/providers'
import { useSitePoints } from 'app/providers/sitePoints/SitePointsProvider'

import dropdownIcon from 'assets/icons/down.svg'
import starIcon from 'features/Widget/assets/icons/star.svg'

import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { APP_CONSTANTS } from 'common/constants/constants'
import { UtilService } from 'common/services/utilService'

import { WidgetPhoto } from 'features/Widget/components/WidgetPhoto/WidgetPhoto'
import { WidgetPoints } from 'features/Widget/components/WidgetPoints/WidgetPoints'
import { WidgetSettings } from 'features/Widget/components/WidgetSettings/WidgetSettings'
import { WIDGET_CONSTANTS } from 'features/Widget/constants/widgetConstants'
import { WidgetService } from 'features/Widget/services/widgetService'

import styles from './widget.module.scss'
import type { ISettingsProfile } from 'features/SettingsOld/interfaces/ISettingsProfile'

const { getInitials } = UtilService
const { getPoints } = WidgetService

export const Widget = ({ userProfile }: { userProfile: ISettingsProfile }) => {
  const { stateUser, isDoctor, hasCompletedDocuments, isStaff } = useAuth()
  const { levels, isFetching } = useSitePoints()
  const settingWrapperRef = useRef<HTMLDivElement>(null)
  const [isOpenPointsCard, togglePointsCard] = useToggle(false)
  const [isOpenProfileSettings, toggleProfileSettings] = useToggle(false)
  const { points = 0, items = [] } = levels ?? {}

  const handleOpenPointsCard = () => {
    if (!isOpenProfileSettings) togglePointsCard(true)
  }

  const handleOpenProfileSettings = () => {
    if (isOpenPointsCard && !isOpenProfileSettings) togglePointsCard(false)
    toggleProfileSettings()
  }

  const handleLeaveProfileLevel = () => {
    if (isOpenPointsCard) togglePointsCard()
  }

  const handleCloseSettings = (): void => {
    if (isOpenProfileSettings) toggleProfileSettings()
  }
  useClickAway(settingWrapperRef, handleCloseSettings)
  return (
    <div
      ref={settingWrapperRef}
      onMouseLeave={handleLeaveProfileLevel}
      className={classNames(styles.parent, { [styles.parentSmall]: !isDoctor })}>
      <div className={styles.parentDetails}>
        <Show when={isDoctor}>
          <button className={styles.parentStatus} onMouseOver={handleOpenPointsCard}>
            <img className={styles.parentStar} src={starIcon} alt={ALT_CONSTANTS.STAR_ICON} />
            {getPoints(points)}
          </button>
        </Show>

        <button
          data-cy='profile-dropdown'
          onClick={handleOpenProfileSettings}
          data-validate-identity={isDoctor && !hasCompletedDocuments}
          className={classNames(styles.parentName, { [styles.parentSingleName]: !isDoctor })}>
          {WIDGET_CONSTANTS.PROFILE}
          <img
            className={classNames(styles.parentDropdownStatus, {
              [styles.parentDropdownActive]: isOpenProfileSettings,
            })}
            src={dropdownIcon}
            alt={ALT_CONSTANTS.DROPDOWN_STATUS_ICON}
          />
          <div className={styles.parentWrapper}>
            <WidgetPhoto
              src={userProfile?.profile.image?.url}
              isLimited={isStaff}
              initials={
                getInitials(stateUser?.first_name, stateUser?.last_name) || APP_CONSTANTS.DR
              }
            />
          </div>
        </button>
      </div>
      <WidgetPoints
        points={points}
        pointsList={items}
        isLoading={isFetching}
        isOpen={isOpenPointsCard}
      />
      <WidgetSettings
        basic={!isDoctor}
        isOpen={isOpenProfileSettings}
        handleCloseWidget={toggleProfileSettings}
      />
    </div>
  )
}

import type { FC } from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useCartMonitor } from 'app/providers/index'

import { useAppSelector } from 'common/hooks/redux'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import type { IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartSlotList } from 'features/Cart/components/CartSlotList/CartSlotList'
import { useCartManager } from 'features/Cart/hooks/useCartManager'
import type { ICartOption } from 'features/Cart/interfaces/ICartOption'
import type { IOptionSlot } from 'features/Option'
import { SET_OPTION_TYPE_SLOTS } from 'features/Option'
import { OptionService } from 'features/Option/services/optionService'
import { RESET_OPTION_TYPE_SLOT } from 'features/Option/state/slices/optionSlice'

const { removeSlotInOptions } = OptionService

export const CartOptionSlotList: FC<ICartOption> = ({
  handleEdit,
  handleUpdate,
  updateCartPrice,
}) => {
  const dispatch = useDispatch()
  const { isUpdatingCart } = useCartMonitor()
  const { newLeaseRooms: rooms } = useAppSelector((state) => state.leaseReducer)
  const {
    optionTypeSlots: optionSlots,
    optionTypeFixed: optionFixed,
    optionSelectFixed,
  } = useAppSelector((state) => state.optionReducer)

  const { bookingCart } = useCartManager()

  const updateOptionsSlot = (slotId: number, date: string, optionId?: number): IOptionSlot[] => {
    const updatedOptionSlots: IOptionSlot[] = removeSlotInOptions({
      date,
      slotId,
      optionId,
      options: optionSlots,
    })

    dispatch(SET_OPTION_TYPE_SLOTS(updatedOptionSlots))
    dispatch(RESET_OPTION_TYPE_SLOT())
    return updatedOptionSlots
  }

  const handleRemoveOptionSlot = useCallback(
    (date: string, slot: IRoomTimeSlot, option: ISiteRoom): void => {
      const updatedOptionSlots: IOptionSlot[] = updateOptionsSlot(slot.id, date, option.id)
      updateCartPrice({
        rooms,
        optionSelectFixed,
        options: [...updatedOptionSlots, ...optionFixed],
      })

      handleUpdate({ optionSlots: updatedOptionSlots })
    },
    [rooms, optionSlots, optionSelectFixed, bookingCart, isUpdatingCart, optionFixed],
  )

  if (!optionSlots.length) return null

  return (
    <div className='cart-card'>
      <hr />
      <CartSlotList
        options={optionSlots}
        handleEdit={handleEdit}
        handleRemoveSlot={handleRemoveOptionSlot}
      />
    </div>
  )
}

import type { FC, MouseEvent, TouchEventHandler } from 'react'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage, useLocation } from 'react-use'

import collapse from 'assets/icons/collapse.svg'
import expand from 'assets/icons/expand.svg'
import locationPin from 'assets/icons/location-pin-red.svg'
import type { DraggableData, DraggableEvent } from 'react-draggable'
import Draggable from 'react-draggable'
import { SuffixSelect } from 'common/components/Select'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import { useAppDispatch } from 'common/hooks/redux'
import { useSites } from 'common/hooks/useSites'
import { UtilService } from 'common/services/utilService'
import { useSetDefaultSiteMutation } from 'common/store/api/rootApi'

import { CartIcon, TOGGLE_IS_OPEN_CART_DETAILS, useCartManager } from 'features/Cart'

import styles from './cartWidget.module.scss'
import { PathUtils } from 'routes/routes.utils'
import { PATH_SEGMENT } from 'routes/pathSegments'

export const CartWidget: FC = memo(() => {
  const [isDragging, setIsDragging] = useState(false)
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 })

  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const isDraggingRef = useRef(isDragging)

  const { cartAmount } = useCartManager()
  const { sites, isFetchingSites, selectedSite } = useSites()

  const [changeDefaultSite] = useSetDefaultSiteMutation()
  const [cartWidgetCollapsed, setCartWidgetCollapsed] = useLocalStorage<boolean>(
    LOCALE_STORAGE_KEYS.CART_WIDGET_COLLAPSED,
    false,
  )

  const toggleOpenCart = useCallback(() => {
    dispatch(TOGGLE_IS_OPEN_CART_DETAILS(true))
  }, [dispatch])

  const touchOpenCart: TouchEventHandler = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault()
        return
      }

      setTimeout(() => {
        if (!isDraggingRef.current) {
          dispatch(TOGGLE_IS_OPEN_CART_DETAILS(true))
        }
      }, 500)
    },
    [dispatch],
  )

  const toggleCollapse = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setCartWidgetCollapsed(!cartWidgetCollapsed)
    },
    [cartWidgetCollapsed],
  )

  const touchCollapse: TouchEventHandler = useCallback(
    (e) => {
      e.stopPropagation()
      setCartWidgetCollapsed(!cartWidgetCollapsed)
    },
    [cartWidgetCollapsed],
  )

  const changeSiteHandler = useCallback(
    (code: string) => {
      changeDefaultSite({ site_code: code })
        .unwrap()
        .then(() => {
          const currentPath = location.pathname

          if (currentPath.includes(PATH_SEGMENT.HOME)) {
            navigate(PathUtils.getHome(code))

            return
          }

          const newPath = currentPath.replace(selectedSite?.code, code)

          navigate(newPath)
        })
    },
    [changeDefaultSite, location.pathname, navigate, selectedSite],
  )

  const handleDragStart = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      const deltaX = Math.abs(data.x - initialPosition.x)
      const deltaY = Math.abs(data.y - initialPosition.y)
      const threshold = 20

      if (deltaX > threshold || deltaY > threshold) {
        e.stopPropagation()
        e.preventDefault()
        setIsDragging(true)
      }
    },
    [initialPosition],
  )

  const handleDragStop = useCallback((e: DraggableEvent) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => setIsDragging(false), 100)
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const handleDrag = useCallback(
    (_: DraggableEvent, data: DraggableData) => {
      const deltaX = Math.abs(data.x - initialPosition.x)
      const deltaY = Math.abs(data.y - initialPosition.y)
      const threshold = 5

      if (deltaX > threshold || deltaY > threshold) {
        setIsDragging(true)
      }

      setInitialPosition({ x: data.x, y: data.y })
    },
    [initialPosition],
  )

  useEffect(() => {
    isDraggingRef.current = isDragging
  }, [isDragging])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Draggable onStart={handleDragStart} onDrag={handleDrag} onStop={handleDragStop}>
      <div
        className={!cartWidgetCollapsed ? styles.widgetContainer : styles.widgetContainerCollapsed}>
        <div style={{ width: '100%', height: '100%', pointerEvents: isDragging ? 'none' : 'auto' }}>
          {cartWidgetCollapsed ? (
            <div
              className={styles.widgetCartContainerCollapsed}
              onTouchStart={touchOpenCart}
              onClick={toggleOpenCart}>
              <CartIcon disabled={isDragging} />
              <div
                className={styles.widgetTogglerExpanded}
                onClick={toggleCollapse}
                onTouchStart={touchCollapse}>
                <img draggable={false} src={expand} alt={ALT_CONSTANTS.ACTION_ICON} />
              </div>
            </div>
          ) : (
            <>
              <div
                className={styles.widgetTogglerCollapsed}
                onClick={toggleCollapse}
                onTouchStart={touchCollapse}>
                <img draggable={false} src={collapse} alt={ALT_CONSTANTS.ACTION_ICON} />
              </div>
              <div className={styles.widgetCartContainer}>
                <SuffixSelect
                  disabled={isDragging}
                  isLoading={isFetchingSites}
                  icon={locationPin}
                  dropdownStyle={{ visibility: isDragging ? 'hidden' : 'initial' }}
                  listOptions={sites}
                  value={selectedSite?.code}
                  onChange={changeSiteHandler}
                  customStyles={{
                    borderRadius: '12px',
                    marginBottom: '7px',
                    overflow: 'hidden',
                    borderColor: 'var(--primary-100)',
                    backgroundColor: 'var(--primary-100)',
                    pointerEvents: isDragging ? 'none' : 'auto',
                  }}
                />

                <div
                  className={styles.widgetAmount}
                  {...(!isDragging && {
                    onClick: toggleOpenCart,
                    onTouchStart: touchOpenCart,
                  })}>
                  <p>{UtilService.numberToDollar(cartAmount)}</p>
                  <p className={styles.widgetCart}>
                    <CartIcon isEmbedded disabled={isDragging} />
                    <span>{'Cart ->'}</span>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Draggable>
  )
})

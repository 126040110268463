import './contracts.scss'

import { type FC, memo, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useToggle } from 'react-use'

import { Checkbox, Col, Row, Skeleton } from 'antd'

import { ReactComponent as PrevIcon } from 'assets/icons/left.svg'
import { ReactComponent as NextIcon } from 'assets/icons/right.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import Signature from 'common/components/Signature/Signature'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
} from 'common/constants/buttonConstants'
import { useAppDispatch } from 'common/hooks/redux'
import { UtilService } from 'common/services/utilService'

import { CART_CONSTANTS } from 'features/Cart'
import {
  useGetPromotionSignContractsQuery,
  useLazyGetPromotionContractTemplateQuery,
} from 'features/Home/Promotion/state/promotionsApi'
import { updateSignature } from 'features/Home/Promotion/state/promotionsSlice'
import { PATH_SEGMENT } from 'routes/pathSegments'
import type { PromotionType } from 'features/Home/Promotion/types/promotion.types'
import { BOOKING_STATUS_CODE } from 'common/constants/bookingConstants'
import { PathUtils } from 'routes/routes.utils'

type Props = {
  promotion: PromotionType
}

export const Contracts: FC<Props> = memo(({ promotion }) => {
  const [signature, setSignature] = useState<string | null>(null)
  const [contractStep, setContractStep] = useState(0)
  const [isConfirmed, toggleIsConfirmed] = useToggle(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { bookingId, siteId } = useParams()
  const signatureRef = useRef(null)

  const { data, isLoading } = useGetPromotionSignContractsQuery({ bookingId, siteId })

  const [fetchContract, { data: contract, isFetching: isFetchingContract }] =
    useLazyGetPromotionContractTemplateQuery()

  const clearSignature = () => {
    signatureRef?.current?.clear()
    setSignature(null)
  }

  useEffect(() => {
    if (contractStep >= 0) {
      const id = contractStep === 0 ? bookingId : data?.contracts?.[contractStep]?.code
      fetchContract({ siteId, bookingId: id })
    }
  }, [contractStep, bookingId])

  useEffect(() => {
    if (!promotion) {
      return
    }

    if (promotion?.status_code !== BOOKING_STATUS_CODE.WAITING_TO_SIGN) {
      navigate(PathUtils.getPromotionDetails(siteId, promotion?.code))
    }
  }, [promotion?.status_code])

  const isNextAvailable = contractStep + 1 <= data?.contracts?.length - 1
  const isPrevAvailable = contractStep - 1 >= 0
  const deposit = data?.deposit

  const hasDeposit = deposit > 0

  const isConfirmedSign = isConfirmed || !hasDeposit

  const handleNext = () => {
    if (isNextAvailable) {
      setContractStep((p) => p + 1)
    }
  }

  const handlePrevious = () => {
    if (isPrevAvailable) {
      setContractStep((p) => p - 1)
    }
  }

  const handleCheckout = () => {
    dispatch(updateSignature(signature))
    navigate(`${PATH_SEGMENT.ROOT}${siteId}/promotion/${bookingId}/${PATH_SEGMENT.CHECKOUT}`)
  }

  return (
    <div className='promotion-contract-container'>
      <div className='main-content'>
        <div className='content'>
          <Row justify='space-between' align='middle' gutter={[24, 24]}>
            <Col xs={0} md={4}>
              <div className='start'>
                <button
                  className='arrow-button'
                  onClick={handlePrevious}
                  disabled={!isPrevAvailable}>
                  <PrevIcon />
                </button>
                <span>Previous Contract</span>
              </div>
            </Col>
            <Col md={16} xs={24} className='contract-wrapper'>
              <Show
                when={!isLoading && !isFetchingContract}
                fallback={<Skeleton paragraph={{ rows: 20 }} active />}>
                <h1 className='title'>
                  {data?.contracts?.[contractStep]?.name} Contract{' '}
                  <span className='lease-code'>{data?.contracts?.[contractStep]?.code}</span>
                </h1>
                <div
                  className='contract-content'
                  dangerouslySetInnerHTML={{
                    __html: contract?.content,
                  }}
                />
              </Show>
            </Col>
            <Col xs={0} md={4}>
              <div className='end'>
                <button className='arrow-button' onClick={handleNext} disabled={!isNextAvailable}>
                  <NextIcon />
                </button>
                <span>Next Contract</span>
              </div>
            </Col>
          </Row>
        </div>

        <Row justify='space-between'>
          <Col xs={12} md={0}>
            <div className='start'>
              <button className='arrow-button' onClick={handlePrevious} disabled={!isPrevAvailable}>
                <PrevIcon />
              </button>
              <span>Previous Contract</span>
            </div>
          </Col>
          <Col xs={12} md={0}>
            <div className='end'>
              <button className='arrow-button' onClick={handleNext} disabled={!isNextAvailable}>
                <NextIcon />
              </button>
              <span>Next Contract</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Show when={!isLoading} fallback={<Skeleton.Button className='skeleton-footer' />}>
            <div className='footer'>
              <Show when={hasDeposit}>
                <div className='info'>
                  <Checkbox checked={isConfirmed} onClick={toggleIsConfirmed} />
                  <p className='description'>{`I agree to the ${UtilService.numberToDollar(
                    deposit,
                  )} deposit explained in the contracts.`}</p>
                </div>
              </Show>

              <Row justify={{ xs: 'center', lg: 'start' }} gutter={[24, 24]}>
                <Col xs={24}>
                  <p className='signature-label'>{CART_CONSTANTS.SIGN_PROMOTIONS}</p>
                  <Row align='middle' justify={{ xs: 'center', md: 'start' }} gutter={[24, 24]}>
                    <Col>
                      <Signature
                        disabled={!(!isLoading && isConfirmedSign)}
                        ref={signatureRef}
                        height={100}
                        onFinish={setSignature}
                        width={400}
                      />
                    </Col>
                    <Col>
                      <Row justify={{ xs: 'center', lg: 'start' }} gutter={[24, 24]}>
                        <Col>
                          <Button
                            loading={false}
                            proportion={BUTTON_PROPORTION.MEDIUM}
                            onClick={handleCheckout}
                            disabled={!isConfirmedSign || !signature}>
                            Checkout
                          </Button>
                        </Col>
                        <Show when={isConfirmedSign && signature}>
                          <Col>
                            <Button
                              disabled={isLoading}
                              modifier={BUTTON_MODIFIER.SECONDARY}
                              proportion={BUTTON_PROPORTION.MEDIUM}
                              onClick={clearSignature}>
                              {BUTTON_CONSTANTS.CLEAR_SIGNATURE}
                            </Button>
                          </Col>
                        </Show>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Show>
        </Row>
      </div>
    </div>
  )
})

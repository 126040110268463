import type { FC } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { Spinner } from 'common/components/Spinner'

import { WidgetLevel } from 'features/Widget/components/WidgetLevel/WidgetLevel'
import { WIDGET_CONSTANTS } from 'features/Widget/constants/widgetConstants'
import type { IWidgetLevel } from 'features/Widget/interfaces/IWidget'

import styles from './widgetPoints.module.scss'
import { PathUtils } from 'routes/routes.utils'

interface IProps {
  points: number
  isOpen: boolean
  isLoading: boolean
  pointsList: IWidgetLevel[]
}

export const WidgetPoints: FC<IProps> = ({ points, isOpen, isLoading, pointsList }) => {
  const { siteId } = useParams()

  if (!isOpen) return null
  return (
    <div className={styles.parent}>
      <div className={styles.parentList}>
        <div className={styles.parentSpinner}>
          <Spinner isLoading={isLoading} />
        </div>
        <WidgetLevel userPoints={points} pointsList={pointsList} />
      </div>

      <div className={styles.parentDetails}>
        <NavLink className={styles.parentFaq} to={PathUtils.getFaq(siteId)}>
          {WIDGET_CONSTANTS.READ_MORE_ABOUT_POINTS}
        </NavLink>
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */

import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'

import type { PaginationProps, TablePaginationConfig } from 'antd'
import { Pagination } from 'antd'

import { useScreenWidth } from 'app/hooks/useScreenWidth'
import { useAuth, useNotification } from 'app/providers'

import { Show } from 'common/components/Show/Show'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import type { ITriggerPagRequest, ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { GoodToKnow } from 'features/Home/Book/Booking/BookingDetails/GoodToKnow/GoodToKnow'
import { BookingHeader } from 'features/Home/Book/Booking/BookingDetails/BookingHeader/BookingHeader'
import { BookingNotifications } from 'features/Home/Book/Booking/BookingDetails/BookingNotifications/BookingNotifications'
import { ScreenList } from 'features/Home/Book/Booking/BookingDetails/ScreenList/ScreenList'
import type { IBookingNotification } from 'features/Home/interfaces/IInfo'
import {
  useLazyFetchStorageLeaseNotificationsQuery,
  useRemoveLeaseMediaMutation,
  useUpdateLeaseMediaMutation,
  useUpdateLeaseMusicMutation,
} from 'features/Home/Book/state/api/bookApi'

import styles from './bookingDetails.module.scss'
import type { BookingDetailsProps } from './bookingDetails.types'
import { useBooking } from 'common/hooks/useBooking'
import { BookingDays } from './BookingDays/BookingDays'
import { useParams } from 'react-router-dom'

export const BookingDetails: FC<BookingDetailsProps> = memo(({ booking, isFetchingBooking }) => {
  const [tableParams, setTableParams] = useState<TablePaginationConfig>({
    ...DEFAULT_TABLE_PARAMS,
  })

  const { isDoctor } = useAuth()
  const { screenWidth } = useScreenWidth()
  const { bookingId, siteId } = useParams()
  const { setNotification } = useNotification()
  const { isRoom, isStorage, isEhr, isRecurring, isMailbox } = useBooking(booking)

  const [updateLeaseMedia, { error, isLoading: isUploading }]: ITriggerRequest =
    useUpdateLeaseMediaMutation()
  const [removeLeaseMedia, { isLoading: isRemovingMedia }]: ITriggerRequest =
    useRemoveLeaseMediaMutation()
  const [updateLeaseMusic]: ITriggerRequest = useUpdateLeaseMusicMutation()

  const [fetchStorageLeaseNotifications, { data: notifications }]: ITriggerPagRequest<
    IBookingNotification[]
  > = useLazyFetchStorageLeaseNotificationsQuery()

  const isBookingActive = useMemo(() => booking?.status === 'Active', [booking])
  const hasNotifications = useMemo(
    () => isStorage || isRecurring || isEhr || isMailbox,
    [isStorage, isRecurring, isEhr, isMailbox],
  )

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchStorageLeaseNotifications({
      bookingId,
      siteId,
      params: { ...tableParams },
    })
  }, [tableParams.pageSize, tableParams.current, bookingId, siteId])

  const handleAddMedia = (screen: number, file: File): void => {
    updateLeaseMedia({
      screen,
      file,
      bookingId,
      siteId,
    })
  }

  const handleRemoveMedia = (mediaId: string): void => {
    removeLeaseMedia({
      mediaId,
      bookingId,
      siteId,
    })
  }

  const handleUpdateAmbientMusic = async (ambient_music_id: number): Promise<void> => {
    const response = await updateLeaseMusic({
      ambient_music_id,
      bookingId,
      siteId,
    })
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      setNotification({
        title: 'Ambient music updated',
        description: response.data.message,
        type: ALERT_CONSTANTS.SUCCESS,
      })
    }
  }

  const onNotificationsPageChange = (page: number): void => {
    setTableParams({ ...tableParams, current: page })
  }

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }

  const uploadError = useMemo(() => {
    if (!error) {
      return
    }

    return (error as any).data?.[0]
  }, [error])

  useEffect(() => {
    if (uploadError) {
      setNotification({
        type: ALERT_CONSTANTS.ERROR,
        title: 'Error',
        description: uploadError,
      })
    }
  }, [uploadError])

  return (
    <div className={styles.parent}>
      <div className={styles.parentWrapper}>
        <BookingHeader isDoctor={isDoctor} booking={booking} isLoading={isFetchingBooking} />

        <section className={styles.parentLeaseDetails}>
          <Show when={isRoom}>
            <BookingDays booking={booking} isFetchingBooking={isFetchingBooking} />
          </Show>

          <Show when={hasNotifications}>
            <h1>Notifications</h1>
            {notifications?.items?.map((notification: IBookingNotification, index: number) => (
              <BookingNotifications notification={notification} index={index} />
            ))}

            <Show when={!!notifications?.items?.length}>
              <Pagination
                showSizeChanger={false}
                className={styles.parentNotificationPaginator}
                total={notifications?.total_items}
                itemRender={itemRender}
                onChange={onNotificationsPageChange}
              />
            </Show>
          </Show>
        </section>
        <Show when={isRoom && screenWidth > 1200}>
          <ScreenList
            booking={booking}
            isDisabled={!isBookingActive}
            handleAddMedia={handleAddMedia}
            isFetching={isFetchingBooking}
            isRemovingMedia={isRemovingMedia}
            handleRemoveMedia={handleRemoveMedia}
            isUploading={isUploading}
          />
        </Show>
      </div>

      <GoodToKnow
        withDeposit={isDoctor && isRoom}
        isFetching={isFetchingBooking}
        isBookingActive={isBookingActive}
        booking={{
          ...booking?.good_to_know,
          box_number: booking?.box_number,
          start_date: booking?.start_date,
          lease_type_code: booking?.lease_type_code,
          lease_type_name: booking?.lease_type_name,
          end_date: booking?.end_date,
          hardware_identifier: booking?.hardware_identifier,
        }}
        handleUpdateAmbientMusic={handleUpdateAmbientMusic}
      />

      <Show when={isRoom && screenWidth <= 1200}>
        <ScreenList
          booking={booking}
          isDisabled={!isBookingActive}
          handleAddMedia={handleAddMedia}
          isFetching={isFetchingBooking}
          isRemovingMedia={isRemovingMedia}
          handleRemoveMedia={handleRemoveMedia}
        />
      </Show>
    </div>
  )
})

import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { REDUCERS } from 'common/store/reducerConstants'

import type {
  IBookingInfoRoom,
  IRoomOptionProduct,
  IRoomSelectedDay,
  IRoomSelectedTimeSlot,
  IRoomTimeSlot,
} from 'features/Booking/interfaces/ILeaseInfoRoom'
import { LeaseService, makeLeaseRoomsGreatAgain } from 'features/Booking/services/leaseService'
import type { IBookingSlice } from 'features/Booking/state/interfaces/ILeaseSlice'
import { LeaseBackToBackService } from 'features/Booking/services/leaseBackToBackService'

const initialState: IBookingSlice = {
  newLeaseInfo: {
    price: 0,
    slots: [],
    room: null,
    days: null,
    selectedDays: [],
    selectedDay: null,
  },
  newLeaseRooms: [],
  isCreateNewLease: false,
  reservedService: [],
}

export const leaseSlice = createSlice({
  name: REDUCERS.BOOKING,
  initialState: initialState,
  reducers: {
    UPDATE_NEW_LEASE_ROOM_INFO(state, action: PayloadAction<IBookingInfoRoom>) {
      state.newLeaseInfo = { ...state.newLeaseInfo, ...action.payload }
    },

    UPDATE_NEW_LEASE_ROOM_DATE_INFO(state, action: PayloadAction<IRoomSelectedDay>) {
      const { selectedDays, selectedDay } = state.newLeaseInfo
      const reservedDay: IRoomSelectedDay | undefined = selectedDays.find(
        (selectedDay: IRoomSelectedDay): boolean => selectedDay.date === action.payload.date,
      )
      if (reservedDay) state.newLeaseInfo.selectedDay = reservedDay
      else state.newLeaseInfo.selectedDay = { ...selectedDay, ...action.payload }
    },

    FILTER_NEW_LEASE_SELECTED_SLOTS(state, action: PayloadAction<IRoomTimeSlot>) {
      const { selectedDay, selectedDays } = state.newLeaseInfo
      const filteredSlots: IRoomSelectedTimeSlot[] = selectedDay.selectedSlots.filter(
        (selectedSlot: IRoomSelectedTimeSlot): boolean => selectedSlot.id !== action.payload.id,
      )

      if (!filteredSlots.length)
        state.newLeaseInfo.selectedDays = selectedDays.filter(
          (day: IRoomSelectedDay): boolean => day.date !== selectedDay.date,
        )
      else
        state.newLeaseInfo.selectedDays = selectedDays.map((day: IRoomSelectedDay) =>
          day.date === selectedDay.date ? selectedDay : day,
        )

      state.newLeaseInfo.selectedDay.selectedSlot = null
      state.newLeaseInfo.selectedDay.selectedSlots = filteredSlots
    },

    UPDATE_NEW_LEASE_SELECTED_DAY(state, action: PayloadAction<IRoomSelectedDay>) {
      state.newLeaseInfo.selectedDay = action.payload
    },

    UPDATE_NEW_LEASE_SELECTED_SLOTS(state, action: PayloadAction<IRoomSelectedTimeSlot[]>) {
      state.newLeaseInfo.selectedDay.selectedSlots = action.payload
    },

    UPDATE_NEW_LEASE_ROOM_DAYS(state) {
      const { selectedDay, selectedDays, slots } = state.newLeaseInfo
      const selectedDayIndex: number = selectedDays.findIndex(
        (day: IRoomSelectedDay): boolean => day.date === selectedDay.date,
      )
      if (selectedDayIndex === -1) state.newLeaseInfo.selectedDays.push({ ...selectedDay, slots })
      else state.newLeaseInfo.selectedDays.splice(selectedDayIndex, 1, { ...selectedDay, slots })
    },

    UPDATE_NEW_LEASE_SELECTED_SLOT(state, action: PayloadAction<IRoomTimeSlot | null>) {
      const { selectedSlots } = state.newLeaseInfo.selectedDay
      if (action.payload) {
        const activeTimeSlot: IRoomSelectedTimeSlot | undefined = selectedSlots?.find(
          (slot: IRoomSelectedTimeSlot): boolean => slot.id === action.payload.id,
        )
        if (activeTimeSlot) {
          state.newLeaseInfo.selectedDay.selectedSlot = { ...activeTimeSlot, ...action.payload }
        } else {
          state.newLeaseInfo.selectedDay.selectedSlot = {
            ...action.payload,
            selectedOptions: [],
          }
        }
      } else {
        state.newLeaseInfo.selectedDay.selectedSlot = null
      }
    },

    SET_NEW_LEASE_SELECTED_SLOTS(state, action: PayloadAction<IRoomSelectedTimeSlot[]>) {
      state.newLeaseInfo.selectedDay.selectedSlots = action.payload
    },

    UPDATE_NEW_LEASE_SELECTED_SLOT_OPTIONS(state, action: PayloadAction<IRoomOptionProduct[]>) {
      state.newLeaseInfo.selectedDay.selectedSlot.selectedOptions = action.payload
    },

    UPDATE_SELECTED_SLOT_OPTION_STOCK(state, action: PayloadAction<IRoomOptionProduct>) {
      const { selectedOptions } = state.newLeaseInfo.selectedDay.selectedSlot
      state.newLeaseInfo.selectedDay.selectedSlot.selectedOptions = selectedOptions.map(
        (selectedOption: IRoomOptionProduct): IRoomOptionProduct =>
          selectedOption.id === action.payload.id ? action.payload : selectedOption,
      )
    },

    UPDATE_NEW_LEASE_PRICE(state) {
      const { room, selectedDays } = state.newLeaseInfo
      let updatedLeasePrice: number = 0

      if (room) {
        updatedLeasePrice = LeaseService.getLeaseRoomsPrice(selectedDays)
        state.newLeaseInfo.price = updatedLeasePrice
      }
    },

    TOGGLE_IS_CREATE_NEW_LEASE(state) {
      state.isCreateNewLease = !state.isCreateNewLease
    },

    RESET_NEW_LEASE_ROOM_INFO(state) {
      state.newLeaseInfo = initialState.newLeaseInfo
    },
    SET_NEW_LEASE_ROOMS(state, action: PayloadAction<IBookingInfoRoom[]>) {
      state.newLeaseRooms = action.payload
    },
  },
})

export const {
  UPDATE_NEW_LEASE_PRICE,
  UPDATE_NEW_LEASE_ROOM_INFO,
  RESET_NEW_LEASE_ROOM_INFO,
  UPDATE_NEW_LEASE_ROOM_DAYS,
  UPDATE_NEW_LEASE_SELECTED_DAY,
  TOGGLE_IS_CREATE_NEW_LEASE,
  UPDATE_NEW_LEASE_SELECTED_SLOT,
  UPDATE_SELECTED_SLOT_OPTION_STOCK,
  UPDATE_NEW_LEASE_SELECTED_SLOT_OPTIONS,
  FILTER_NEW_LEASE_SELECTED_SLOTS,
  UPDATE_NEW_LEASE_ROOM_DATE_INFO,
  SET_NEW_LEASE_ROOMS,
  SET_NEW_LEASE_SELECTED_SLOTS,
} = leaseSlice.actions

const { updateRoomServicePlacement, updateDaySlots } = LeaseBackToBackService

export const autoSaveSlotsAsync = createAsyncThunk(
  'booking/autoSaveSlots',
  async (_, { dispatch, getState }) => {
    const state = getState() as { leaseReducer: IBookingSlice }
    const { selectedDay } = state.leaseReducer.newLeaseInfo

    // Step 1: Update the day with slots and service placement
    let updatedDay = updateDaySlots(selectedDay)
    updatedDay = updateRoomServicePlacement(updatedDay)

    // Step 2: Update selected day in store
    dispatch(UPDATE_NEW_LEASE_SELECTED_DAY(updatedDay))

    // Step 3: Update room days array
    dispatch(UPDATE_NEW_LEASE_ROOM_DAYS())

    // Step 4: Get fresh state after updates
    const updatedState = getState() as { leaseReducer: IBookingSlice }
    const { newLeaseInfo, newLeaseRooms } = updatedState.leaseReducer

    // Step 5: Create updated lease with fresh state
    const updatedLease = makeLeaseRoomsGreatAgain(
      LeaseService.getUpdatedLease(newLeaseInfo, newLeaseRooms),
    )

    // Step 6: Update lease rooms in store
    dispatch(SET_NEW_LEASE_ROOMS(updatedLease))

    return updatedLease
  },
)
